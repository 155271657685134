import { useRef } from 'react';
import { dateToString } from '../helper/dates';
import Grid from './Grid';
import { GridAnexos } from './GridAnexos';

import { useSystemContext } from '../Context/SystemContext';
import { DateInput, DBSelectInput, Input, SelectInput, TextInput } from './Input';

const yup = require('../interface/yup-br');

export const translateStatus5W2H = (status) => {
    switch (status) {
        case 'Vencer':
            return 'À vencer';
        case 'Vencido':
            return 'Vencido';
        case 'Concluído':
            return 'Concluído';
        default:
            return status;
    }
};


export const Grid5W2H = ({ url, GridHeaders, LineDataPrint, useAttachments = false, GridTitle = '5W2H', ...rest }) => {
    const System = useSystemContext();
    const gridRef = useRef(null);
    let _GridHeaders = [
        { title: 'O que', field: 'what', sort: true, className: 'w-7/12 text-left pl-1' },
        { title: 'Quem', field: 'crew.nome', sort: true, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
        { title: 'Quando', field: 'when', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
        {
            title: 'Status',
            field: 'status',
            filter: {
                type: 'select',
                definition: [
                    { value: 'Vencer', label: 'À vencer' },
                    { value: 'Vencido', label: 'Vencido' },
                    { value: 'Concluído', label: 'Concluído' },
                ],
            },

            sort: true,
            className: 'w-1/12 pl-1 text-left border-l border-gray-300',
        },
    ];

    return (
        <Grid
            url={url}
            externalRef={gridRef}
            hideIndexColumn={true}
            titlePDF={`${System.getMenuTitle(rest.perms)} - 5W2H`}
            {...rest}
            disableModal={useAttachments === false}
            GridHeaders={_GridHeaders}
            LineDataPrint={(data) => {
                return [data?.what, data?.crew?.nome, dateToString(data?.when), translateStatus5W2H(data?.status)];
            }}
            GridTitle={GridTitle}
            forceReloadAfterSave={true}
            onRenderForm={(GridModes, setFieldValue, values) => {
                return (
                    <div>
                        <Input name="what" label="O que?" className="w-5/6" />
                        <TextInput name="why" label="Por que?" rows={'3'} />
                        <DBSelectInput name="who" label="Quem?" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                        <DateInput name="when" label="Quando?" />
                        <Input name="where" label="Onde?" />
                        <TextInput name="how" label="Como?" />
                        <Input name="howmuch" label="Quanto?" />
                        <SelectInput name="status" label="Status" listOptions={[
                            { option: 'À vencer', value: 'Vencer' },
                            { option: 'Vencido', value: 'Vencido' },
                            { option: 'Concluído', value: 'Concluído' }
                        ]} />
                        <TextInput name="observacoes" label="Observações / Evidências" rows={'3'} />
                    </div>
                );
            }}
            FormTabs={
                useAttachments
                    ? [
                        () => {
                            return <div>5W2H</div>;
                        },
                        () => {
                            return <div>Anexos</div>;
                        },
                    ]
                    : []
            }
            onRenderFormExtra={
                useAttachments
                    ? [
                        (GridModes, item, GridRefresh) => {
                            return (
                                <div className="flex flex-col">
                                    <GridAnexos url={`/attachment/${useAttachments}-${item._id}`} perms={rest.perms} />
                                </div>
                            );
                        },
                    ]
                    : null
            }
            validationSchema={yup.object().shape({
                what: yup.string(),
                who: yup.string(),
                when: yup.string(),
                where: yup.string(),
                why: yup.string(),
                how: yup.string(),
                howmuch: yup.string(),
                status: yup.string(),
                observacoes: yup.string(),
            })}
            onPrepareSaveRecord={(values) => {
                let newForm = new FormData();
                for (let key in values) {
                    newForm.append(key, values[key]);
                }
                return newForm;
            }}
        />
    );
};

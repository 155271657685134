import { Fragment, useEffect, useRef, useState } from 'react';
import { Grid5W2H } from '../components/Grid5W2H';
import { GridAnexos } from '../components/GridAnexos';


import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Grid from '../components/Grid';
import { AttachmentInput, CheckBoxInput, DBSelectInput, DateInput, GroupInput, SelectInput, TextInput } from '../components/Input';
import { dateToString } from '../helper/dates';
const yup = require('../interface/yup-br');

export const AppSubmitForm = () => {
    const gridRef = useRef();
    let System = useSystemContext();
    const [forms, setForms] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await System.apiGet(`/appform`); // recebe todos os forms para renderizar
            setForms(response.data);
        })();
        // eslint-disable-next-line
    }, []);

    const listAvaliacao = {
        'critico': 'Crítico',
        'nao_critico': 'Não crítico',
        'na': 'Não se aplica',
    };
    return (
        <div className="m-4 mx-2 ">
            <Grid
                url={'/appsubmitform'}
                perms={'1'}
                externalRef={gridRef}
                disableModal={true}
                titlePDF="Desvios SMS"
                GridHeaders={[
                    { title: 'Usuário', field: 'crew.nome', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                    { title: 'Colaborador', field: 'colaborador.nome', sort: true, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
                    { title: 'Área', field: 'area.area', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                    { title: 'Unidade de negócio', field: 'colaborador.unidadenegocio.unidade', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                    { title: 'Data', field: 'data', type: 'date', sort: true, className: 'w-1/12 text-left pl-1' },
                    { title: 'Avaliação', field: 'avaliacao', type: 'string', sort: true, className: 'w-1/12 text-left pl-1' },
                    { title: 'Encerramento', field: 'encerramento', type: 'boolean', sort: true, className: 'w-1/12 text-center pl-1' },
                    {
                        title: 'Imagem',
                        field: 'attachment',
                        filter: false,
                        sort: false,
                        type: 'fileview',
                        className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                    },
                ]}
                LineDataPrint={(data) => {
                    return [
                        data.crew?.nome,
                        data.colaborador?.nome,
                        data.area?.area,
                        data.colaborador?.unidadenegocio?.unidade,
                        dateToString(data.data),
                        listAvaliacao[data.avaliacao],
                        data.encerramento ? dateToString(data.dataencerramento) : '',
                        { fileid: data.attachment }
                    ];
                }}
                onRenderForm={(GridModes, SetFieldValue, values) => {
                    if (GridModes.insert_mode) {
                        let activeForm = forms.find(form => form.active);
                        SetFieldValue('formid', activeForm?._id);
                        values.formid = activeForm?._id;
                        console.log(forms);
                    }
                    return (
                        <Fragment>
                            <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                            </div>
                            <div className="block pb-2 border-b border-gray-300">
                                <div className="inline-flex w-full">
                                    <div className='w-2/5'>
                                        <DBSelectInput
                                            name="responsavel"
                                            label="Usuário"
                                            returnField="userID"
                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                            displaySelectField="nome"
                                        />
                                        <DBSelectInput
                                            name="colaboradorid"
                                            label="Nome"
                                            url={'/crewactive'}
                                            displaySelectField="nome"
                                        />
                                        <GroupInput>
                                            <DateInput name="data" label="Data" className="w-1/5" />
                                            <DBSelectInput
                                                name="areaid"
                                                label="Área"
                                                url={'/areauser'}
                                                displaySelectField="area"
                                            />
                                        </GroupInput>
                                        <TextInput name="detalhamento" label="Detalhamento" className="w-full" rows="4" />
                                        <TextInput name="acaoimediata" label="Ação imediata" className="w-full" rows="4" />

                                    </div>
                                    <div className='w-3/5 mt-1 border'>
                                        <div className='w-full pl-2 bg-amarelo-eq'>Formulário: {values.form.titulo}</div>
                                        {forms.find(form => form._id === values.formid)?.requisitos?.map((requisito, index) => (
                                            <div key={index} className="w-full">
                                                <div className="w-full p-2 text-left ">
                                                    <span className='font-semibold'>
                                                        {index + 1}. {requisito.requisito}
                                                    </span>
                                                    {requisito.items.map((item, indexItem) => (
                                                        <CheckBoxInput
                                                            key={uuid()}
                                                            className="w-full mr-2"
                                                            name={`requisito_${requisito._id}_${item._id}`}
                                                            label={item.item}
                                                        />
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            <GroupInput>
                                <SelectInput
                                    name="avaliacao"
                                    label="Avaliação de Critícidade"
                                    className={'w-3/12'}
                                    listOptions={[
                                        { option: 'Crítico', value: 'critico' },
                                        { option: 'Não crítico', value: 'nao_critico' },
                                        { option: 'Não se aplica', value: 'na' },
                                    ]}
                                />
                                <DBSelectInput
                                    name="responsavelQSMS"
                                    label="Responsável"
                                    returnField="userID"
                                    url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                    displaySelectField="nome"
                                />
                            </GroupInput>
                            <GroupInput>
                                <AttachmentInput name="attachment" perms="10" label="Imagem Anexada" />
                            </GroupInput>
                            <GroupInput>
                                <DateInput name="dataencerramento" label="Data encerramento" className="w-2/12" />
                                <div className='w-2/12 mt-8'>
                                    <CheckBoxInput
                                        key={uuid()}
                                        name="encerramento"
                                        label="Encerramento"
                                    />
                                </div>
                            </GroupInput>
                        </Fragment>
                    );

                }}
                validationSchema={
                    yup.object().shape({
                        colaboradorid: yup.string().required(),
                        areaid: yup.string().required(),
                        data: yup.date().required(),
                        acaoimediata: yup.string().required('Informe a ação imediata'),
                    })
                }
                FormTabs={[
                    () => {
                        return <div>Home</div>;
                    },
                    () => {
                        return <div>5W2H</div>;
                    },
                    () => {
                        return <div>Anexos</div>;
                    }
                ]}
                onRenderFormExtra={[
                    (GridModes, item, GridRefresh) => {
                        // 5W2H
                        return (
                            <div>
                                <Grid5W2H url={`/method5w2h/appf10-${item._id}`} perms={'10'} />
                            </div>
                        );
                    },
                    (GridModes, item, GridRefresh) => {
                        // Anexo
                        return (
                            <div className={'m-2'}>
                                <GridAnexos url={`/attachment/appf10-${item._id}`} disableModal={true} perms={'10'} />
                            </div>
                        );
                    }
                ]}
            />
        </div >
    );
}

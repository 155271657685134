import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Grid from '../components/Grid';
import { translateStatus5W2H } from '../components/Grid5W2H';
import { dateToString } from '../helper/dates';

export function DesviosDashboard() {
    const System = useSystemContext();
    return (
        <div className="w-full bg-white">
            <Grid
                url={'/dashboard'}
                hideIndexColumn={true}
                preventAutoEdit={true}
                canEdit={false}
                canDelete={false}
                defaultSortField="@quando"
                defaultItemsPerPage={20}
                showSearch={true}
                canInsert={false}
                titlePDF={'Dashboard'}
                showPDFExport={false}
                showCSVExport={true}
                GridHeaders={[
                    { title: 'Fase', field: 'fase', sort: true, className: 'w-3/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'O que', field: 'oque', sort: true, className: 'w-4/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Quem', field: 'quem', sort: true, className: 'w-3/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Quando', field: 'quando', sort: true, type: 'date', className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Ações', field: 'acoes', sort: false, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                ]}
                hideActionsButtons={true}
                LineDataPrint={(item) => {
                    let _dt = dateToString(item.quando);
                    let dateObject = new Date(item.quando + ' 00:00:00');
                    dateObject.setDate(dateObject.getDate() + 1);
                    let _vencido = dateObject < new Date();
                    return [
                        item.fase,
                        item.oque,
                        item.quem,
                        translateStatus5W2H(item.status),
                        _vencido ? `#color=red:${_dt}` : _dt,
                        <div to={item.link} onClick={() => {
                            System.setAutoEdit(item.autoEdit);
                            window.location.href = '/database/10'; // Adicionado para mudar a janela atual
                        }
                        }>
                            <BiEdit className="w-full mt-1 cursor-pointer hover:text-blue-800" title="Editar" key={uuidv4()} />
                        </div>,
                    ];
                }}
            />
        </div>
    );
}
